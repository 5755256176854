window.$ = window.jQuery = require('jquery');
require('bootstrap');
require('@fancyapps/fancybox');
import ScrollReveal from 'scrollreveal';
import Swiper, {
  Navigation,
  Pagination,
  Scrollbar,
  Autoplay,
  EffectFade,
  Thumbs,
} from 'swiper';
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import 'swiper/scss/effect-fade';

Swiper.use([Navigation, Pagination, Scrollbar, Autoplay, EffectFade, Thumbs]);

(function ($) {
  var $body = $('body');

  var fn = {
    /*
      Launch
    */

    Launch: function () {
      fn.Navigation();
      fn.Carousels();
      fn.Lightbox();
      fn.Animations();
      fn.App();
    },

    /*
      Navigation
    */

    Navigation: function () {
      $body.append('<div class="layer"></div>');

      $body.on('click', '.layer, .megamenu__close', function () {
        $('.parent').removeClass('parent--active');
        $('.offcanvas').removeClass('offcanvas--active');
        $('.mobile-search').removeClass('mobile-search--active');
        $body.removeClass('mobile-menu-active layer-active offcanvas-active');
      });

      // Mobile menu

      $body.on('click', '.menu-btn', function (e) {
        e.preventDefault();

        $body.toggleClass('mobile-menu-active');
        $body.removeClass('layer-active');
        $('.mobile-search').removeClass('mobile-search--active');
      });

      $('[data-submenu-back]').on('click', function (e) {
        e.preventDefault();

        $(this).closest('[data-has-submenu]').removeClass('active');
        console.log($(this).closest('[data-has-submenu]'));
      });

      $('[data-has-submenu] > a').on('click', function (e) {
        e.preventDefault();

        $(this).closest('li').toggleClass('active');
      });

      // Desktop menu

      var $parentMenu = $('.header__menu .parent');

      $parentMenu.each(function () {
        var i = 0;

        $(this)
          .find('[data-animate-menuitem]')
          .each(function () {
            var $item = $(this);

            $item.css({
              'animation-delay': i + 's',
            });

            i += 0.15;
          });
      });

      $parentMenu.on('click', '> a', function (e) {
        e.preventDefault();

        if ($body.hasClass('layer-active')) {
          $('li').removeClass('parent--active');

          $('li')
            .find('[data-animate-menuitem]')
            .each(function () {
              var $item = $(this);

              $item.removeClass($item.data('animate-menuitem'));
            });

          $('.parent').removeClass('parent--active');
          $body.removeClass('mobile-menu-active layer-active offcanvas-active');
        } else {
          var $parent = $(this).closest('li.parent');

          $parent.addClass('parent--active');
          $body.addClass('layer-active');

          $parent.find('[data-animate-menuitem]').each(function () {
            var $item = $(this);

            $item.addClass($item.data('animate-menuitem'));
          });
        }
      });

      $('.header__dropdown').on('mouseover', function (e) {
        e.preventDefault();

        $body.addClass('layer-active');
      });

      $('.header__dropdown').on('mouseout', function (e) {
        e.preventDefault();

        $('.parent').removeClass('parent--active');
        $body.removeClass('layer-active');
      });

      // Profile menu

      $('[data-card-nav-toggle]').on('click', function (e) {
        e.preventDefault();

        $(this).closest('.card').find('[data-card-nav]').toggleClass('d-none');
      });

      // Footer menu

      $('.footer__nav').on('click', 'li > span', function (e) {
        e.preventDefault();

        $(this).closest('li').toggleClass('active');
      });
    },

    /*
      Carousels
    */

    Carousels: function () {
      new Swiper('.hero-slider', {
        loop: true,
        slidesPerView: 1,
        spaceBetween: 0,
        effect: 'fade',
        fadeEffect: {
          crossFade: true,
        },
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
        speed: 400,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      });

      new Swiper('.logo-carousel', {
        loop: false,
        spaceBetween: 5,
        slidesPerView: 'auto',
        slidesOffsetBefore: 15,
        slidesOffsetAfter: 15,
        breakpoints: {
          575: {
            spaceBetween: 15,
            slidesPerView: 3,
            slidesOffsetBefore: 0,
            slidesOffsetAfter: 0,
          },
          992: {
            spaceBetween: 15,
            slidesPerView: 5,
            slidesOffsetBefore: 0,
            slidesOffsetAfter: 0,
          },
          1300: {
            spaceBetween: 15,
            slidesPerView: 8,
            slidesOffsetBefore: 0,
            slidesOffsetAfter: 0,
          },
        },
      });

      new Swiper('.card-md-carousel', {
        loop: false,
        slidesPerView: 'auto',
        spaceBetween: 16,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        breakpoints: {
          992: {
            slidesPerView: 4,
          },
        },
      });

      new Swiper('.card-lg-carousel', {
        loop: false,
        slidesPerView: 'auto',
        spaceBetween: 16,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        breakpoints: {
          992: {
            slidesPerView: 3,
          },
        },
      });

      new Swiper('.figure-carousel', {
        loop: false,
        slidesPerView: 'auto',
        spaceBetween: 16,
        breakpoints: {
          992: {
            slidesPerView: 3,
          },
        },
      });

      new Swiper('.picture-carousel', {
        loop: true,
        slidesPerView: 1,
        spaceBetween: 4,
        loopedSlides: 2,
      });

      var galleryThumbs = new Swiper('.gallery-thumbs', {
        loop: true,
        spaceBetween: 20,
        slidesPerView: 3,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
      });

      new Swiper('.gallery-large', {
        loop: true,
        effect: 'fade',
        fadeEffect: {
          crossFade: true,
        },
        thumbs: {
          swiper: galleryThumbs,
        },
      });

      new Swiper('.text-slider', {
        slidesPerView: 1,
        loop: true,
        direction: 'vertical',
        speed: 1000,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      });
    },

    /*
      Lightbox
    */

    Lightbox: function () {
      $('[data-fancybox]').fancybox({
        animationEffect: 'fade',
        buttons: ['close'],
      });
    },

    /*
      Animations
    */

    Animations: function () {
      $('[data-animate-text]').each(function () {
        var $text = $(this),
          animateText = $text.html().split(' ');

        $text.html(
          '<span><span data-animate-group="wordFadeInUp">' +
            animateText.join(
              '</span></span> <span><span data-animate-group="wordFadeInUp">'
            ) +
            '</span></span>'
        );
      });

      ScrollReveal().reveal('[data-animate-delay]', {
        viewFactor: 0.3,
        duration: 0,
        interval: 150,
        beforeReveal: function (el) {
          el.classList.add('scroll-animation');
          el.classList.add($(el).data('animate-delay'));
        },
      });

      ScrollReveal().reveal('[data-animate-group]', {
        viewFactor: 0.3,
        interval: 180,
        beforeReveal: function (el) {
          el.classList.add('scroll-animation');
          el.classList.add($(el).data('animate-group'));
        },
      });

      ScrollReveal().reveal('[data-animate]', {
        viewFactor: 0.3,
        duration: 0,
        beforeReveal: function (el) {
          el.classList.add($(el).data('animate'));
        },
      });
    },

    /*
      App
    */

    App: function () {
      // Offcanvas

      $body.on('click', '[data-offcanvas-open]', function (e) {
        var $anchor = $(this),
          $section = $($anchor.attr('href'));

        if ($section.length) {
          e.preventDefault();

          $body.addClass('layer-active offcanvas-active');
          $section.addClass('offcanvas--active');
        }
      });

      $body.on('click', '[data-offcanvas-close]', function (e) {
        e.preventDefault();

        $body.removeClass('layer-active offcanvas-active');
        $('.offcanvas').removeClass('offcanvas--active');
      });
    },
  };

  fn.Launch();
})(jQuery);
